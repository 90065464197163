import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-progress-bar",
    templateUrl: "./progress-bar.component.html",
    styleUrls: ["./progress-bar.component.scss"]
})
export class ProgressBarComponent implements OnInit {
    @Input() completedSteps: number = 0;
    @Input() totalSteps: number = 3;

    public stepList: Array<number> = [];

    constructor() {}

    ngOnInit(): void {
        this.stepList = Array(this.totalSteps + 1);
    }

    public get stepSize(): number {
        return 100 / this.totalSteps;
    }
}
