import { Injectable } from "@angular/core";
import {
    AnalyticsInteraction,
    AnalyticsInteractionsMap
} from "src/app/common/interfaces/AnalyticsInteraction.interface";

import * as analyticsConfig from "src/app/common/configs/analytics-interactions.config";
import { environment } from "src/environments/environment";

declare const window: any;

@Injectable({
    providedIn: "root"
})
export class AnalyticsService {
    private _interactions: AnalyticsInteractionsMap | undefined;

    constructor() {}

    public trackPageView(pageName: string, url?: string, extraArgs?: Record<string, any>): void {
        if (!pageName) {
            return;
        }

        if (window.gtag) {
            const metaData = this.getDefaultMetaData();

            window.gtag("event", "page_view", {
                page_title: pageName,
                page_location: url || window.location.href,
                ...extraArgs,
                ...metaData
            });
        }
    }

    public trackCustomEvent(eventName: string, extraArgs?: Record<string, any>): void {
        const metaData = this.getDefaultMetaData();

        if (window.gtag) {
            window.gtag("event", eventName, { ...extraArgs, ...metaData });
        }
    }

    public async trackInteraction(interactionId: string, extraArgs?: Record<string, any>): Promise<void> {
        const interaction = await this.getInteractionById(interactionId);

        if (!interaction || !interaction.eventName) {
            return;
        }

        const metaData = this.getDefaultMetaData();

        if (window.gtag) {
            window.gtag("event", interaction.eventName, {
                event_category: interaction.category,
                event_label: interaction.label,
                ...extraArgs,
                ...metaData
            });
        }
    }

    private async getInteractionById(interactionId: string): Promise<AnalyticsInteraction | undefined> {
        const interactions = await this.getAnalyticsFromConfig();

        if (!interactions) {
            return;
        }

        const key = Object.keys(interactions).find((id) => {
            return id === interactionId;
        });

        if (key) {
            return interactions?.[key];
        }

        return;
    }

    private async getAnalyticsFromConfig(): Promise<AnalyticsInteractionsMap> {
        if (this._interactions) {
            return this._interactions;
        }

        if (analyticsConfig.interactions) {
            this._interactions = analyticsConfig.interactions;
            return this._interactions;
        }

        return {};
    }

    private getDefaultMetaData(): Record<string, any> {
        const metaData: any = {
            environment_type: environment.isBrightSign ? "Kiosk" : "Online"
        };

        return metaData;
    }
}
