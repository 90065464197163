<div
    *ngIf="selectedPlanDetails"
    class="page"
    (touchstart)="handleTouchStart($event)"
    (touchend)="handleTouchEnd($event)"
>
    <h1 class="page-heading">{{ selectedPlanDetails.title }}</h1>
    <p class="page-copy">{{ selectedPlanDetails.summary }}</p>

    <div class="plans">
        <div *ngFor="let plan of selectedPlanDetails.plans" class="plan plan--detail">
            <div class="column">
                <h2 class="plan__title">{{ plan.title }}</h2>
                <p class="plan__description">{{ plan.description }}</p>
                <ng-container *ngIf="!isBrightSign">
                    <a class="plan__link" [href]="plan.link" target="_blank" (click)="trackLinkInteraction(plan.title)">
                        <img class="plan__link-icon" src="assets/icons/icon-next.svg" alt="Link Icon" />
                        <span class="plan__link-text">Learn more</span>
                    </a>
                </ng-container>
            </div>
            <div *ngIf="isBrightSign" class="c-qr-code">
                <img *ngIf="showQrCode" [src]="plan.qrFileName" alt="QR Code" />
            </div>
        </div>
    </div>
    <button *ngIf="isBackButtonShowing" class="side-button side-button--prev" appDelayedClick (clicked)="goBack()">
        <span class="side-button__icon"></span>
    </button>
</div>
