export const imageAssets: string[] = [
    "assets/images/home-bg.jpg",
    "assets/icons/icon-home-red.png",
    "assets/icons/icon-home.png",
    "assets/icons/icon-next.svg",
    "assets/icons/scroll-more.png",
    "assets/icons/scroll-top.png",
    "assets/images/logos/logo-toyota-financial-services-white.svg",
    "assets/images/logos/logo-toyota-financial-services.svg",
    "assets/images/qr-codes/EWU.png",
    "assets/images/qr-codes/Guaranteed_Auto_Protection_GAP.png",
    "assets/images/qr-codes/Key_Placement.png",
    "assets/images/qr-codes/Tire_Wheel_Gold.png",
    "assets/images/qr-codes/Tire_Wheel_Platinum.png",
    "assets/images/qr-codes/Toyota_Auto_Care.png",
    "assets/images/qr-codes/ToyotaCare_Plus.png",
    "assets/images/qr-codes/VSA_Gold.png",
    "assets/images/qr-codes/VSA_Platinum.png",
    "assets/images/qr-codes/VSA_Powertrain.png"
];
