import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PlanOverview } from "src/app/common/interfaces/Plan.interface";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";

@UntilDestroy()
@Component({
    selector: "app-recommended-plans",
    templateUrl: "./recommended-plans.component.html",
    styleUrls: ["./recommended-plans.component.scss"]
})
export class RecommendedPlansComponent implements OnInit, AfterViewInit {
    public planOverviews: PlanOverview[] = [];
    public isBrightSign: boolean = environment.isBrightSign; // env variable
    public scrollDownShowing: boolean = false;
    public scrollUpShowing: boolean = false;

    @ViewChild("plans") plans!: ElementRef;

    constructor(private route: ActivatedRoute, private analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
            this.planOverviews = data["planOverviews"] || [];
        });
    }

    ngAfterViewInit() {
        this.canScroll();
    }

    public trackInteraction(label: string): void {
        this.analyticsService.trackCustomEvent("learn_more", {
            event_category: "Recommended Plans",
            event_label: label
        });
    }

    private canScroll() {
        const availableHeight = this.plans.nativeElement.clientHeight;
        const elementHeight = this.plans.nativeElement.scrollHeight;
        if (availableHeight >= elementHeight) {
            this.scrollDownShowing = false;
        } else {
            this.scrollDownShowing = true;
        }
    }

    public onElementScroll(event: any) {
        if (event.target.scrollTop === 0) {
            this.scrollDownShowing = true;
            this.scrollUpShowing = false;
        } else if (event.target.scrollTop === event.target.scrollHeight - event.target.clientHeight) {
            this.scrollDownShowing = false;
            this.scrollUpShowing = true;
        }
    }

    public scrollDown() {
        this.plans.nativeElement.scrollTo({ top: this.plans.nativeElement.scrollHeight, behavior: "smooth" });
    }

    public scrollUp() {
        this.plans.nativeElement.scrollTo({ top: 0, behavior: "smooth" });
    }
}
