import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { fadeIn } from "src/app/common/animations";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
    animations: [fadeIn("0.2s ease-in-out")]
})
export class HomeComponent implements OnInit, OnDestroy {
    public redAnimationActive: boolean = false;
    public whiteAnimationActive: boolean = false;

    private animationTimeout: any;

    constructor(private router: Router, private analyticsService: AnalyticsService) {}

    ngOnDestroy(): void {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
        }
    }

    ngOnInit(): void {}

    public startQuestionnaire(): void {
        this.redAnimationActive = true;
        this.whiteAnimationActive = true;

        this.animationTimeout = setTimeout(() => {
            this.analyticsService.trackInteraction("START_QUESTIONNAIRE");
            this.router.navigate(["questionnaire"]);
        }, 1500);
    }
}
