<header class="header">
    <div class="logo">
        <img
            class="logo__img"
            src="assets/images/logos/logo-toyota-financial-services.svg"
            alt="Toyota Financial Services Logo"
        />
    </div>
    <button class="icon-button home-button" appDelayedClick [resetAfterClick]="true" (clicked)="goHome()">
        <img
            class="home-button__icon"
            src="assets/icons/icon-home{{ iconColor }}.png"
            alt="Home Button"
            (click)="activeRed()"
        />
    </button>
</header>
