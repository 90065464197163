<div class="progress">
    <div class="progress__track">
        <div class="progress__indicator" [ngStyle]="{ width: stepSize * completedSteps + '%' }"></div>
    </div>
    <div
        *ngFor="let step of stepList; let i = index"
        class="progress__step"
        [ngStyle]="{ left: i * stepSize + '%' }"
        [ngClass]="{ active: completedSteps >= i }"
    ></div>
</div>
