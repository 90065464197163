import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge } from "rxjs";
import { fadeIn } from "src/app/common/animations";
import { MAXIMUM_SWIPE_TIME, MINIMUM_SWIPE_DISTANCE } from "src/app/common/constants/slide.constants";
import { Choice, Question, QuestionMap } from "src/app/common/interfaces/Questionnaire.interface";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { QuestionnaireService } from "src/app/shared/services/questionnaire.service";
import { Swiper, SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";
import * as questionnaireConfig from "../../common/configs/questionnaire.config";

@UntilDestroy()
@Component({
    selector: "app-questionnaire",
    templateUrl: "./questionnaire.component.html",
    styleUrls: ["./questionnaire.component.scss"],
    animations: [fadeIn("0.2s ease-in-out")]
})
export class QuestionnaireComponent implements OnInit {
    @ViewChild("swiperSlider", { static: false }) swiperSlider?: SwiperComponent;

    public allQuestions: QuestionMap = {};
    public displayedQuestions: Question[] = [];

    public questionnaireForm!: FormGroup;
    public questionCount: number = 3;

    public upcomingQuestion: Question | null = null;

    public completedSteps: number = 0;

    private lastSlideTouchStartX: number = 0;
    private lastSlideTouchEndX: number = 0;

    private isSubmitted = false;

    private readonly SUBMISSION_TIMEOUT = 350;

    private swipeTime: number = 0;

    constructor(
        private formBuilder: FormBuilder,
        private questionnaireService: QuestionnaireService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (questionnaireConfig.questions) {
            this.allQuestions = questionnaireConfig.questions;
            this.displayedQuestions = [this.allQuestions["buying-process"]];

            this.questionnaireForm = this.initForm();

            merge(this.step1.valueChanges, this.step2.valueChanges, this.step3.valueChanges)
                .pipe(untilDestroyed(this))
                .subscribe((choice: Choice) => {
                    this.questionnaireForm.updateValueAndValidity({ emitEvent: false });

                    this.updateUpcomingSlide(choice);
                });
        }
    }

    public updateUpcomingSlide(choice: Choice): void {
        const currentIndex = this.swiper?.realIndex || 0;

        if (currentIndex >= this.questionCount) {
            return;
        }

        if (choice.nextQuestionId) {
            this.upcomingQuestion = this.getNextQuestion(choice.nextQuestionId);
        } else {
            this.upcomingQuestion = null;
        }

        if (this.upcomingQuestion) {
            this.displayedQuestions = this.displayedQuestions.slice(0, currentIndex + 1).concat(this.upcomingQuestion);
        } else {
            this.displayedQuestions = this.displayedQuestions.slice(0, currentIndex + 1);
        }
    }

    public get swiperOptions(): SwiperOptions {
        return {
            slidesPerView: 1,
            initialSlide: 1,
            resistanceRatio: 0,
            allowSlideNext: this.buttonEnabled && !this.isLastStep
        };
    }

    private initForm(): FormGroup {
        return this.formBuilder.group({
            "1": new FormControl<Choice | undefined>(undefined, Validators.required),
            "2": new FormControl<Choice | undefined>(undefined, Validators.required),
            "3": new FormControl<Choice | undefined>(undefined, Validators.required)
        });
    }

    public handleNext(): void {
        if (!this.questionId) {
            throw Error("Error submitting question");
        }

        const isValid = this.choiceControl.valid;
        const choice = this.choiceControl.value;

        if (choice && isValid) {
            this.analyticsService.trackCustomEvent("next_question", {
                event_category: "Questionnaire",
                event_label: "Next",
                question_number: this.displayedQuestions.length,
                question_text: this.displayedQuestions[this.displayedQuestions.length - 1]?.text,
                question_response: this.questionnaireForm.get(this.questionId)?.value.text
            });

            if (choice.nextQuestionId) {
                const nextQuestion = this.getNextQuestion(choice.nextQuestionId);

                if (nextQuestion) {
                    setTimeout(() => {
                        this.swiper?.slideNext();
                    }, 0);
                }
            } else {
                this.submit();
            }
        } else {
            throw Error("Something went wrong");
        }
    }

    private getNextQuestion(nextQuestionId: string): Question | null {
        return this.allQuestions[nextQuestionId] || null;
    }

    public submit(): void {
        if (this.isSubmitted) {
            return;
        }

        if (this.questionnaireForm.valid) {
            this.isSubmitted = true;
            this.analyticsService.trackInteraction("QUIZ_COMPLETED");

            const selectionChoices = this.questionnaireForm.value;

            const resultGroupId = this.getResultIdFromSelections(selectionChoices);

            this.questionnaireService.setResultOverviewIds(resultGroupId);

            this.completedSteps += 1;

            setTimeout(() => {
                this.router.navigate(["/recommended-plans"]);
            }, this.SUBMISSION_TIMEOUT);
        }
    }

    private getResultIdFromSelections(choices: Record<string, Choice>): string {
        const values = Object.values(choices);

        if (values.length) {
            const finalChoice = Object.values(choices).pop();

            return finalChoice?.resultGroupId || "";
        }

        return "";
    }

    public updateCompletedSteps(event: any): void {
        if (event) {
            const currentIndex = event[0].realIndex;

            this.completedSteps = currentIndex;

            this.changeDetectorRef.detectChanges();
        }
    }

    public handleTouchStart([swiper, event]: [Swiper, MouseEvent | TouchEvent | PointerEvent]): void {
        if (!this.isLastStep) {
            return;
        }

        const touchEvent: TouchEvent = <TouchEvent>event;

        this.swipeTime = new Date().getTime();

        if (touchEvent.changedTouches) {
            this.lastSlideTouchStartX = touchEvent.changedTouches[0].screenX;
        }
    }

    public handleTouchEnd([swiper, event]: [Swiper, MouseEvent | TouchEvent | PointerEvent]): void {
        if (!this.isLastStep) {
            return;
        }

        const touchEvent: TouchEvent = <TouchEvent>event;

        if (touchEvent.changedTouches) {
            this.lastSlideTouchEndX = touchEvent.changedTouches[0].screenX;
        }
        this.handleLastSlideSwipe();
    }

    private handleLastSlideSwipe(): void {
        if (!this.isLastStep) {
            return;
        }

        const distance = this.lastSlideTouchStartX - this.lastSlideTouchEndX;

        const now = new Date().getTime();
        const swipeDuration = now - this.swipeTime;

        if (distance > 0 && distance > MINIMUM_SWIPE_DISTANCE && swipeDuration < MAXIMUM_SWIPE_TIME) {
            this.ngZone.runGuarded(() => {
                this.submit();
            });
        }
    }

    private get swiper(): Swiper | undefined {
        return this.swiperSlider?.swiperRef;
    }

    private get isLastStep(): boolean {
        return this.swiper?.realIndex === this.questionCount - 1;
    }

    public get questionId(): string {
        if (this.swiper) {
            return `${this.swiper.realIndex + 1}`;
        } else {
            return "1";
        }
    }

    public get buttonEnabled(): boolean {
        const isValid = this.choiceControl.valid;
        const choice = this.choiceControl.value;

        return isValid && !!choice;
    }

    public get choiceControl(): FormControl {
        return this.questionnaireForm.get(this.questionId) as FormControl;
    }

    public get step1(): FormControl {
        return this.questionnaireForm.get("1") as FormControl;
    }
    public get step2(): FormControl {
        return this.questionnaireForm.get("2") as FormControl;
    }
    public get step3(): FormControl {
        return this.questionnaireForm.get("3") as FormControl;
    }
}
