import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, TitleStrategy } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { environment } from "src/environments/environment";
import { SwiperModule } from "swiper/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TemplatePageTitleStrategy } from "./common/providers/template-page-title-strategy.provider";
import { HomeComponent } from "./pages/home/home.component";
import { PlanDetailComponent } from "./pages/plan-detail/plan-detail.component";
import { ProgressBarComponent } from "./pages/questionnaire/progress-bar/progress-bar.component";
import { QuestionnaireComponent } from "./pages/questionnaire/questionnaire.component";
import { PlanOverviewComponent } from "./pages/recommended-plans/plan-overview/plan-overview.component";
import { RecommendedPlansComponent } from "./pages/recommended-plans/recommended-plans.component";
import { TestSentryComponent } from "./pages/test-sentry/test-sentry.component";
import { SharedModule } from "./shared/shared.module";
import { QuestionSlideComponent } from "./pages/questionnaire/question-slide/question-slide.component";
@NgModule({
    declarations: [
        AppComponent,
        TestSentryComponent,
        HomeComponent,
        QuestionnaireComponent,
        ProgressBarComponent,
        RecommendedPlansComponent,
        PlanOverviewComponent,
        PlanDetailComponent,
        QuestionSlideComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        SwiperModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
                logErrors: environment.name === "local"
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        },
        {
            provide: TitleStrategy,
            useClass: TemplatePageTitleStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
