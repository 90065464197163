import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { QuestionnaireService } from "../../services/questionnaire.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
    public iconColor: string = "";
    constructor(private router: Router, private questionnaireService: QuestionnaireService) {}

    ngOnInit(): void {}

    public goHome(): void {
        this.questionnaireService.clearResults();
        this.router.navigate([""]);
    }

    activeRed() {
        this.iconColor = "-red";
    }
}
