import { QuestionnaireResultMap } from "../interfaces/Questionnaire.interface";

export const resultGroups: QuestionnaireResultMap = {
    group1: ["vehicle-service-agreements", "guaranteed-auto", "tire-and-wheel", "toyota-care-plus"],
    group2: ["vehicle-service-agreements", "guaranteed-auto", "tire-and-wheel", "toyota-auto-care-classic"],
    group3: ["vehicle-service-agreements", "guaranteed-auto", "tire-and-wheel", "excess-wear-and-use"],
    group4: ["vehicle-service-agreements", "toyota-care-plus"],
    group5: ["vehicle-service-agreements"],
    group6: ["vehicle-service-agreements", "excess-wear-and-use", "tire-and-wheel"]
};
