import { PlanOverviewMap } from "../interfaces/Plan.interface";

export const planOverviews: PlanOverviewMap = {
    "vehicle-service-agreements": {
        title: "Vehicle Service Agreements",
        description:
            "VSA can help cover eligible repair costs due to mechanical failure that occur outside your standard warranty.",
        pageId: "vehicle-service-agreements"
    },
    "guaranteed-auto": {
        title: "Guaranteed Auto Protection",
        description: "GAP can help cover certain expenses in the event your car is declared a total loss.",
        pageId: "guaranteed-auto-protection"
    },
    "tire-and-wheel": {
        title: "Tire & Wheel Protection",
        description: "Covers you if your tires become damaged as a result of certain eligible road hazards.",
        pageId: "tire-and-wheel-protection"
    },
    "toyota-care-plus": {
        title: "Toyota Care Plus",
        description:
            "This prepaid plan covers regularly scheduled maintenance, major services, and 24-hour Roadside Assistance.",
        pageId: "toyota-prepaid-maintenance"
    },
    "toyota-auto-care-classic": {
        title: "Toyota Auto Care Classic",
        description:
            "This prepaid maintenance plan covers regularly scheduled maintenance and roadside assistance during the term of the agreement, and is only available when you purchase your vehicle.",
        pageId: "toyota-prepaid-maintenance"
    },
    "excess-wear-and-use": {
        title: "Excess Wear & Use Protection",
        description: "Excess Wear & Use Protection helps protect you from potential costs at your vehicle’s lease-end.",
        pageId: "excess-wear-and-use-protection"
    }
};
