import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import {
    PlanDetailPage,
    PlanDetailPageMap,
    PlanOverview,
    PlanOverviewMap
} from "src/app/common/interfaces/Plan.interface";
import * as planDetailConfig from "../../common/configs/plan-detail.config";
import * as plansConfig from "../../common/configs/plans.config";
@Injectable({
    providedIn: "root"
})
export class PlanService {
    private _planOverviewConfig: PlanOverviewMap | null = null;
    private _planDetailConfig: PlanDetailPageMap | null = null;

    constructor() {}

    public getPlanOverviewsFromIdList(ids: string[]): PlanOverview[] {
        const allPlanOverviews = this.getAllPlanOverviews();

        if (allPlanOverviews) {
            return Object.entries(allPlanOverviews)
                .filter(([id, _]) => {
                    return ids.includes(id);
                })
                .map(([_, plan]) => {
                    return plan;
                });
        }

        return [];
    }

    public getPlanDetailsFromId(id: string): Observable<PlanDetailPage | null> {
        const planDetailsMap = this.getAllPlansDetails();

        if (planDetailsMap) {
            const plan = planDetailsMap[id];

            if (plan) {
                return of(plan);
            }
        }

        return of(null);
    }

    private getAllPlanOverviews(): PlanOverviewMap {
        if (this._planOverviewConfig) {
            return this._planOverviewConfig;
        }

        if (plansConfig && plansConfig.planOverviews) {
            this._planOverviewConfig = plansConfig.planOverviews;
            return plansConfig.planOverviews;
        }

        return {};
    }

    private getAllPlansDetails(): PlanDetailPageMap {
        if (this._planDetailConfig) {
            return this._planDetailConfig;
        } else if (planDetailConfig && planDetailConfig.planDetailPages) {
            this._planDetailConfig = planDetailConfig.planDetailPages;
            return this._planDetailConfig;
        }

        return {};
    }
}
