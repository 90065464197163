import { AnalyticsInteractionsMap } from "../interfaces/AnalyticsInteraction.interface";

export const interactions: AnalyticsInteractionsMap = {
    START_QUESTIONNAIRE: {
        eventName: "start_questionnaire",
        category: "Home",
        label: "Find Your Plan"
    },
    HOME: {
        eventName: "home",
        category: "Header",
        label: "Home"
    },
    QUIZ_COMPLETED: {
        eventName: "quiz_completed",
        category: "Questionnaire",
        label: "Quiz Completed"
    }
};
