import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { map, Observable } from "rxjs";
import { PlanDetailPage } from "src/app/common/interfaces/Plan.interface";
import { PlanService } from "src/app/shared/services/plan.service";

@Injectable({
    providedIn: "root"
})
export class PlanDetailResolver implements Resolve<PlanDetailPage | null> {
    constructor(private planService: PlanService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlanDetailPage | null> | null {
        const pageId = route.paramMap.get("pageId");

        if (pageId) {
            return this.planService.getPlanDetailsFromId(pageId).pipe(
                map((planDetail) => {
                    return planDetail;
                })
            );
        }

        return null;
    }
}
