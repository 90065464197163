import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges
} from "@angular/core";

@Directive({
    selector: "[appDelayedClick]"
})
export class DelayedClickDirective implements OnInit, OnChanges {
    @Input() resetAfterClick: boolean = false;
    @Input() disabledByComponent: boolean = false;

    @Output() clicked = new EventEmitter();

    private timeout: any;
    private isActive: boolean = false;

    private readonly DELAY_IN_MILLISECONDS: number = 300;

    @HostListener("click", ["$event"])
    clickEvent(event: MouseEvent) {
        if (!this.isActive && !this.disabledByComponent) {
            event.preventDefault();
            event.stopPropagation();

            this.renderer.addClass(this.elRef.nativeElement, "active");
            this.renderer.setProperty(this.elRef.nativeElement, "disabled", true);

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.clicked.emit(event);
                this.isActive = false;

                if (this.resetAfterClick) {
                    this.renderer.removeClass(this.elRef.nativeElement, "active");
                    this.renderer.setProperty(this.elRef.nativeElement, "disabled", false);
                }
            }, this.DELAY_IN_MILLISECONDS);
        }
    }

    constructor(private renderer: Renderer2, private elRef: ElementRef) {}
    ngOnChanges(changes: SimpleChanges): void {
        if ("disabledByComponent" in changes) {
            this.disabledByComponent = changes["disabledByComponent"].currentValue;

            if (this.disabledByComponent) {
                this.renderer.setProperty(this.elRef.nativeElement, "disabled", true);
            } else {
                this.renderer.setProperty(this.elRef.nativeElement, "disabled", false);
            }
        }
    }

    ngOnInit(): void {
        if (this.disabledByComponent) {
            this.renderer.setProperty(this.elRef.nativeElement, "disabled", true);
        }
    }
}
