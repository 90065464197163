<div class="slide" [formGroup]="form" [ngClass]="{ 'last-slide': +currentQuestionId === totalSlides }">
    <h3 class="question-number">Question {{ slideIndex + 1 }}</h3>
    <hr class="separator" />
    <h2 class="question-text">{{ question.text }}</h2>
    <button
        *ngIf="valid"
        class="side-button side-button--next"
        appDelayedClick
        (clicked)="handleNext()"
        [disabledByComponent]="+currentQuestionId - 1 > slideIndex"
        [resetAfterClick]="true"
    >
        <span class="side-button__icon"></span>
    </button>
    <div
        class="c-radio-button"
        [ngClass]="{ 'c-radio-button--first': choiceIndex === 0 }"
        *ngFor="let choice of question.choices; let choiceIndex = index"
    >
        <label class="radio-button" [for]="choice.id" [attr.disabled]="choice.disabled">
            <input
                [id]="choice.id"
                type="radio"
                [value]="choice"
                [formControlName]="formControlId"
                [attr.disabled]="choice.disabled"
            />
            <span class="radio-button__indicator"></span>
            <span class="radio-button__label" [ngClass]="{ 'radio-button__label--disabled': choice.disabled }">
                {{ choice.text }}
            </span>
        </label>
    </div>
</div>
