import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HeaderComponent } from "./components/header/header.component";
import { DelayedClickDirective } from "./directives/delayed-click.directive";

@NgModule({
    declarations: [HeaderComponent, DelayedClickDirective],
    exports: [HeaderComponent, DelayedClickDirective],
    imports: [CommonModule]
})
export class SharedModule {}
