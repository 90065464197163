import { PlanDetailPageMap } from "../interfaces/Plan.interface";

export const planDetailPages: PlanDetailPageMap = {
    "vehicle-service-agreements": {
        title: "Vehicle Service Agreements",
        summary: "Scan each QR code to learn more about each VSA protection plan.",
        plans: [
            {
                title: "Platinum Protection",
                description: "Cover your new Toyota with our highest level of service agreement protection.",
                link: "https://qrco.de/bdXaCb",
                qrFileName: "assets/images/qr-codes/VSA_Platinum.png"
            },
            {
                title: "Gold Protection",
                description:
                    "Not ready to go Platinum? No worries. Our Gold VSA offers coverage for your new Toyota’s many eligible component repairs and parts.",
                link: "https://qrco.de/bdXaE8",
                qrFileName: "assets/images/qr-codes/VSA_Gold.png"
            },
            {
                title: "Powertrain Protection",
                description:
                    "Reset Assured. Your Toyota’s engine, transmission, axle-assembly and other eligible components are covered after your vehicle’s factory warranty expires.",
                link: "https://qrco.de/bdXaFU",
                qrFileName: "assets/images/qr-codes/VSA_Powertrain.png"
            }
        ]
    },
    "guaranteed-auto-protection": {
        title: "Guaranteed Auto Protection",
        summary: "Scan each QR code to learn more the plan.",
        plans: [
            {
                title: "Guaranteed Auto Protection",
                description:
                    "Guaranteed Auto Protection (GAP) waives or pays the balance (minus certain fees and charges) between the amount still owed on your finance or lease contract and your auto insurance settlement.",
                link: "https://qrco.de/bdXaGH",
                qrFileName: "assets/images/qr-codes/Guaranteed_Auto_Protection_GAP.png"
            }
        ]
    },
    "toyota-prepaid-maintenance": {
        title: "Toyota Prepaid Maintenance",
        summary: "Scan each QR code to learn more about each Toyota Prepaid Maintenance plan.",
        plans: [
            {
                title: "ToyotaCare Plus",
                description: "Enjoy stress-free maintenance coverage.",
                link: "https://qrco.de/bdXaGk",
                qrFileName: "assets/images/qr-codes/ToyotaCare_Plus.png"
            },
            {
                title: "Toyota Auto Care",
                description:
                    "Your used Toyota is also your new ride. Help keep it running smoothly with coverage on basic maintenance.",
                link: "https://qrco.de/bdXaJ1",
                qrFileName: "assets/images/qr-codes/Toyota_Auto_Care.png"
            }
        ]
    },
    "tire-and-wheel-protection": {
        title: "Tire & Wheel Protection",
        summary: "Scan each QR code to learn more about each T&W protection plan.",
        plans: [
            {
                title: "T & W Protection Gold",
                description:
                    "With Tire & Wheel Protection Gold, we’ll help keep your tires, wheels and your budget intact with protection that’s available for up to 6 years.",
                link: "https://qrco.de/bdXaML",
                qrFileName: "assets/images/qr-codes/Tire_Wheel_Gold.png"
            },
            {
                title: "T & W Protection Platinum",
                description:
                    "With coverage available for up to 6 years, Tire & Wheel Protection Platinum includes all the Gold benefits, plus Paintless Dent Repair and Windshield Repair.",
                link: "https://qrco.de/bdXaNO",
                qrFileName: "assets/images/qr-codes/Tire_Wheel_Platinum.png"
            },
            {
                title: "Key Replacement Protection",
                description:
                    "Whether your keys are lost, stolen, or damaged, you’ll be taken care of with Key Replacement Protection. Key Replacement Protection can be added to Tire & Wheel Gold or Platinum plans, or purchased as stand-alone coverage separately.",
                link: "https://qrco.de/bdXaO3",
                qrFileName: "assets/images/qr-codes/Key_Placement.png"
            }
        ]
    },
    "excess-wear-and-use-protection": {
        title: "Excess Wear & Use Protection",
        summary: "Scan each QR code to learn more the plan.",
        plans: [
            {
                title: "Excess Wear & Use Protection",
                description:
                    "It’s a lot more fun to be thinking about your next vehicle at the end of your lease. Thanks to Excess Wear & Use Protection you won’t be worrying about how to pay for certain dents and dings when you turn in your vehicle.",
                link: "https://qrco.de/bdXaOa",
                qrFileName: "assets/images/qr-codes/EWU.png"
            }
        ]
    }
};
