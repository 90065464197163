import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { map, Observable } from "rxjs";
import { PlanOverview } from "src/app/common/interfaces/Plan.interface";
import { PlanService } from "src/app/shared/services/plan.service";
import { QuestionnaireService } from "src/app/shared/services/questionnaire.service";

@Injectable({
    providedIn: "root"
})
export class RecommendedPlansResolverResolver implements Resolve<PlanOverview[]> {
    constructor(private questionnaireService: QuestionnaireService, private planService: PlanService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlanOverview[]> {
        return this.questionnaireService.getResultOverviewIds().pipe(
            map((overviewIds) => {
                return this.planService.getPlanOverviewsFromIdList(overviewIds);
            })
        );
    }
}
