<div class="page" [@fadeIn]>
    <form *ngIf="questionnaireForm" [formGroup]="questionnaireForm">
        <div class="c-progress-bar">
            <app-progress-bar class="progress" [completedSteps]="completedSteps"></app-progress-bar>
        </div>
        <swiper
            *ngIf="displayedQuestions.length"
            #swiperSlider
            [config]="swiperOptions"
            (slideChange)="updateCompletedSteps($event)"
            (touchStart)="handleTouchStart($event)"
            (touchEnd)="handleTouchEnd($event)"
        >
            <ng-template swiperSlide *ngFor="let question of displayedQuestions; let i = index">
                <app-question-slide
                    [formGroup]="questionnaireForm"
                    [question]="question"
                    [currentQuestionId]="questionId"
                    [formControlId]="i + 1 + ''"
                    [slideIndex]="i"
                    [totalSlides]="questionCount"
                    (nextClicked)="handleNext()"
                ></app-question-slide>
            </ng-template>
        </swiper>
    </form>
</div>
