import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ChildrenOutletContexts, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { delay, filter, fromEvent, map, Observable } from "rxjs";
import { fadeIn, slider } from "./common/animations";
import { AnalyticsService } from "./shared/services/analytics.service";
import { PreloadService } from "./shared/services/preload.service";

@UntilDestroy()
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    animations: [fadeIn("0.2s ease-in-out"), slider]
})
export class AppComponent implements OnInit {
    private resizeObservable$: Observable<Event> = new Observable<Event>();

    public showHeader: boolean = true;

    public hasPreloaded: boolean = false;

    title = "Toyota Financial Services";

    constructor(
        private router: Router,
        private titleService: Title,
        private analyticsService: AnalyticsService,
        private contexts: ChildrenOutletContexts,
        private preloadService: PreloadService
    ) {}

    ngOnInit(): void {
        this.setupViewableContainerCssVar();

        this.resizeObservable$ = fromEvent(window, "resize");
        this.resizeObservable$.subscribe((evt) => {
            this.setupViewableContainerCssVar();
        });

        this.router.events
            .pipe(filter((event): event is RouterEvent => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.showHeader = event.url !== "/";
            });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                delay(0),
                map(() => this.titleService.getTitle())
            )
            .subscribe((title) => {
                this.analyticsService.trackPageView(title);
            });

        this.preloadService
            .preloadAssets()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.hasPreloaded = true;
            });
    }

    public getRouteAnimationData() {
        return this.contexts.getContext("primary")?.route?.snapshot?.data?.["animation"];
    }

    private setupViewableContainerCssVar() {
        const viewableHeight = `${window.innerHeight}px`;
        const rootEl = document.documentElement;
        rootEl.style.setProperty("--app-height", viewableHeight);
    }
}
