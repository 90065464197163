import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MAXIMUM_SWIPE_TIME, MINIMUM_SWIPE_DISTANCE } from "src/app/common/constants/slide.constants";
import { PlanDetailPage } from "src/app/common/interfaces/Plan.interface";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { QuestionnaireService } from "src/app/shared/services/questionnaire.service";
import { environment } from "src/environments/environment";

@UntilDestroy()
@Component({
    selector: "app-plan-detail",
    templateUrl: "./plan-detail.component.html",
    styleUrls: ["../../../scss/plans.scss", "./plan-detail.component.scss"]
})
export class PlanDetailComponent implements OnInit {
    public selectedPlanDetails: PlanDetailPage | null = null;

    public isBrightSign: boolean = environment.isBrightSign; // env variable
    public isBackButtonShowing: boolean = false;

    private pageId: string = "";

    private touchStartX: number = 0;
    private touchEndX: number = 0;
    private swipeTime: number = 0;

    public showQrCode: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private router: Router,
        private questionnaireService: QuestionnaireService
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.selectedPlanDetails = this.route.snapshot.data["planDetails"];
        });

        this.questionnaireService
            .getResultOverviewIds()
            .pipe(untilDestroyed(this))
            .subscribe((resultIds) => {
                this.isBackButtonShowing = resultIds.length > 0;
            });

        this.fadeInQrCode();
    }

    public trackLinkInteraction(label: string): void {
        this.analyticsService.trackCustomEvent("learn_more_external", {
            event_category: "Plan Details",
            event_label: label,
            detail_page_title: this.selectedPlanDetails?.title
        });
    }

    public goBack(): void {
        this.analyticsService.trackCustomEvent("back_to_results", {
            event_category: "Plan Details",
            event_label: "Back",
            detail_page_title: this.selectedPlanDetails?.title
        });
        this.router.navigateByUrl("/recommended-plans");
    }

    public handleTouchStart(event: TouchEvent): void {
        if (!this.isBackButtonShowing) {
            return;
        }

        this.swipeTime = new Date().getTime();
        this.touchStartX = event.changedTouches[0].screenX;
    }

    public handleTouchEnd(event: TouchEvent): void {
        if (!this.isBackButtonShowing) {
            return;
        }

        this.touchEndX = event.changedTouches[0].screenX;

        this.handleSwipe();
    }

    private handleSwipe(): void {
        const distance = this.touchEndX - this.touchStartX; // Swipe to the right

        const now = new Date().getTime();

        const swipeDuration = now - this.swipeTime;

        if (distance > 0 && distance > MINIMUM_SWIPE_DISTANCE && swipeDuration < MAXIMUM_SWIPE_TIME) {
            this.router.navigate(["/recommended-plans"]);
        }
    }

    private fadeInQrCode() {
        setTimeout(() => {
            this.showQrCode = true;

            const qrCode = document.querySelectorAll(".c-qr-code");
            qrCode.forEach((qrCode) => {
                qrCode?.classList.add("fade-in");
            });
        }, 600);
    }
}
