import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-test-sentry",
    templateUrl: "./test-sentry.component.html",
    styleUrls: ["./test-sentry.component.scss"]
})
export class TestSentryComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        throw new Error("Sentry test error thrown");
    }
}
