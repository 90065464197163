import { QuestionMap } from "../interfaces/Questionnaire.interface";

export const questions: QuestionMap = {
    "buying-process": {
        text: "How far along are you in the buying process?",
        choices: [
            {
                id: "still-shopping",
                text: "I'm still shopping for a Toyota.",
                nextQuestionId: "plan-buy-or-lease"
            },
            {
                id: "purchased-or-leased",
                text: "I recently purchased or leased a Toyota.",
                nextQuestionId: "purchased-or-leased"
            }
        ]
    },

    "plan-buy-or-lease": {
        text: "Do you plan to buy or lease?",
        choices: [
            {
                id: "buy",
                text: "I plan to buy.",
                nextQuestionId: "buy-new-cert-used-or-used"
            },
            {
                id: "lease",
                text: "I plan to lease.",
                nextQuestionId: "lease-new-or-cert-used"
            }
        ]
    },
    "purchased-or-leased": {
        text: "Did you buy or lease your Toyota?",
        choices: [
            {
                id: "bought",
                text: "I recently bought my Toyota.",
                nextQuestionId: "bought-new-or-cert-used"
            },
            {
                id: "leased",
                text: "I recently leased my Toyota.",
                nextQuestionId: "leased-new-or-cert-used"
            }
        ]
    },
    "buy-new-cert-used-or-used": {
        text: "Are you looking to buy a New, Certified Used or Used Toyota?",
        choices: [
            {
                id: "buy-new",
                text: "New",
                resultGroupId: "group1"
            },
            {
                id: "buy-cert-used",
                text: "Certified Used",
                resultGroupId: "group2"
            },
            {
                id: "buy-used",
                text: "Used Toyota",
                resultGroupId: "group2"
            }
        ]
    },
    "lease-new-or-cert-used": {
        text: "Are you looking to buy a New or Certified Used?",
        choices: [
            {
                id: "lease-new",
                text: "New",
                resultGroupId: "group3"
            },
            {
                id: "lease-cert-used",
                text: "Certified Used",
                disabled: true
            }
        ]
    },
    "bought-new-or-cert-used": {
        text: "Did you buy a New or Certified Used?",
        choices: [
            {
                id: "bought-new",
                text: "New",
                resultGroupId: "group4"
            },
            {
                id: "bought-cert-used",
                text: "Certified Used",
                resultGroupId: "group5"
            }
        ]
    },
    "leased-new-or-cert-used": {
        text: "Did you buy a New or Certified Used?",
        choices: [
            {
                id: "leased-new",
                text: "New",
                resultGroupId: "group6"
            },
            {
                id: "leased-cert-used",
                text: "Certified Used",
                disabled: true
            }
        ]
    }
};
