import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { QuestionnaireResultMap } from "src/app/common/interfaces/Questionnaire.interface";
import * as resultsConfig from "../../common/configs/results.config";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class QuestionnaireService {
    private _resultOverviewIds$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    private _resultGroups: QuestionnaireResultMap | undefined;

    constructor(private storageService: StorageService) {
        this.initializeStoredResults();
    }

    public initializeStoredResults(): void {
        const resultGroup = this.storageService.getSavedData();

        if (resultGroup) {
            this.updateResultIds(resultGroup);
        } else {
            this._resultOverviewIds$.next([]);
        }
    }

    public getResultOverviewIds(): Observable<string[]> {
        return this._resultOverviewIds$.asObservable();
    }

    public clearResults(): void {
        this._resultOverviewIds$.next([]);
        this.storageService.clearSavedData();
    }

    public setResultOverviewIds(resultGroupId: string): void {
        this.storageService.setSavedData(resultGroupId);
        this.updateResultIds(resultGroupId);
    }

    private updateResultIds(resultGroupId: string): void {
        const resultGroups = this.getResultIdsByGroupId(resultGroupId);

        this._resultOverviewIds$.next(resultGroups);
    }

    public getResultIdsByGroupId(resultGroupId: string): string[] {
        const resultGroups = this.getAllResults();

        if (resultGroups && Object.keys(resultGroups).includes(resultGroupId)) {
            return resultGroups[resultGroupId];
        }

        return [];
    }

    public getAllResults(): QuestionnaireResultMap {
        if (this._resultGroups) {
            return this._resultGroups;
        }

        if (resultsConfig) {
            this._resultGroups = resultsConfig.resultGroups || {};
            return this._resultGroups;
        }

        return {};
    }
}
