<div class="page" [@fadeIn]>
    <img class="background-image" src="assets/images/home-bg.jpg" alt="Background Image" />
    <div class="logo">
        <img
            src="assets/images/logos/logo-toyota-financial-services-white.svg"
            alt="Toyota Financial Services"
            class="logo__img"
        />
    </div>
    <h1 class="heading">Which Plans are Right for Me?</h1>
    <p class="lead">Just answer a few questions to see if there’s a plan that’s right for you!</p>

    <button class="button button--home" appDelayedClick (clicked)="startQuestionnaire()">Find Your Plan</button>
</div>
<div class="red-animation" [ngClass]="{ 'red-animation--active': redAnimationActive }"></div>
<div class="white-animation" [ngClass]="{ 'white-animation--active': whiteAnimationActive }"></div>
