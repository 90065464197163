import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { map, Observable } from "rxjs";
import { PlanService } from "src/app/shared/services/plan.service";

@Injectable({
    providedIn: "root"
})
export class PlanDetailGuard implements CanActivate {
    constructor(private planService: PlanService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const pageId = route.paramMap.get("pageId");

        if (!pageId) {
            this.router.navigate(["/"]);
            return false;
        }

        return this.planService.getPlanDetailsFromId(pageId).pipe(
            map((planDetail) => {
                const validPage = planDetail !== null;
                if (!validPage) {
                    this.router.navigate(["/"]);
                    return false;
                }

                return true;
            })
        );
    }
}
