<div class="page">
    <h1 class="page-heading">Recommended plans</h1>

    <div class="plans" id="plans" [ngClass]="{ 'c-scroll': isBrightSign }" (scroll)="onElementScroll($event)" #plans>
        <app-plan-overview
            *ngFor="let plan of planOverviews"
            [plan]="plan"
            (linkClicked)="trackInteraction($event)"
        ></app-plan-overview>
        <div
            *ngIf="isBrightSign && scrollDownShowing"
            class="scroll-icon"
            [ngClass]="{ 'scroll-icon--active': scrollDownShowing }"
            (click)="scrollDown()"
        >
            <img src="assets/icons/scroll-more.png" alt="More Scroll Icon" />
        </div>
        <div
            *ngIf="isBrightSign && scrollUpShowing"
            class="scroll-icon"
            [ngClass]="{ 'scroll-icon--active': scrollUpShowing }"
            (click)="scrollUp()"
        >
            <img src="assets/icons/scroll-top.png" alt="Back To Top Scroll Icon" />
        </div>
    </div>
</div>
