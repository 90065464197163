import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanDetailGuard } from "./common/guards/plan-detail.guard";
import { RecommendedPlansGuard } from "./common/guards/recommended-plans.guard";
import { PlanDetailResolver } from "./common/resolvers/plan-detail.resolver";
import { PlanTitleResolver } from "./common/resolvers/plan-title.resolver";
import { RecommendedPlansResolverResolver } from "./common/resolvers/recommended-plans-resolver.resolver";
import { HomeComponent } from "./pages/home/home.component";
import { PlanDetailComponent } from "./pages/plan-detail/plan-detail.component";
import { QuestionnaireComponent } from "./pages/questionnaire/questionnaire.component";
import { RecommendedPlansComponent } from "./pages/recommended-plans/recommended-plans.component";
import { TestSentryComponent } from "./pages/test-sentry/test-sentry.component";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        title: "Home",
        data: {
            animation: 0
        }
    },
    {
        path: "questionnaire",
        component: QuestionnaireComponent,
        title: "Questionnaire",
        data: {
            animation: 0
        }
    },
    {
        path: "recommended-plans",
        component: RecommendedPlansComponent,
        title: "Recommended Plans",
        canActivate: [RecommendedPlansGuard],
        runGuardsAndResolvers: "always",
        resolve: { planOverviews: RecommendedPlansResolverResolver },
        data: {
            animation: 2
        }
    },
    {
        path: "recommended-plans/:pageId",
        title: PlanTitleResolver,
        component: PlanDetailComponent,
        canActivate: [PlanDetailGuard],
        runGuardsAndResolvers: "always",
        resolve: {
            planDetails: PlanDetailResolver
        },
        data: {
            animation: 3
        }
    },
    {
        path: "test-sentry",
        component: TestSentryComponent
    },
    {
        path: "**",
        redirectTo: ""
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    providers: [PlanTitleResolver],
    exports: [RouterModule]
})
export class AppRoutingModule {}
