import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    private readonly STORAGE_KEY: string = "tfs-reload-token";

    constructor() {}

    public getSavedData(): string | null {
        return localStorage.getItem(this.STORAGE_KEY);
    }

    public setSavedData(resultGroupId: string): void {
        localStorage.setItem(this.STORAGE_KEY, resultGroupId);
    }

    public clearSavedData(): void {
        localStorage.removeItem(this.STORAGE_KEY);
    }
}
