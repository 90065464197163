import { Component, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective } from "@angular/forms";
import { Question } from "src/app/common/interfaces/Questionnaire.interface";

@Component({
    selector: "app-question-slide",
    templateUrl: "./question-slide.component.html",
    styleUrls: ["./question-slide.component.scss"]
})
export class QuestionSlideComponent implements OnInit {
    @Output() nextClicked: EventEmitter<void> = new EventEmitter<void>();

    @Input() question!: Question;
    @Input() currentQuestionId!: string;
    @Input() slideIndex: number = 0;
    @Input() buttonEnabled: boolean = false;
    @Input() formControlId: string = "";
    @Input() totalSlides: number = 0;

    public form!: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective, private ngZone: NgZone) {}

    ngOnInit(): void {
        this.form = this.rootFormGroup.control;
    }

    public handleNext(): void {
        this.ngZone.runGuarded(() => {
            this.nextClicked.emit();
        });
    }

    public get valid(): boolean {
        return this.formControl.valid;
    }

    public get formControl(): FormControl {
        return this.form.get(this.formControlId) as FormControl;
    }
}
