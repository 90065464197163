import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
    private readonly APP_TITLE: string = "Toyota Financial Services";

    constructor(private readonly title: Title) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);
        if (title !== undefined) {
            this.title.setTitle(`${title} | ${this.APP_TITLE}`);
        } else {
            this.title.setTitle(this.APP_TITLE);
        }
    }
}
