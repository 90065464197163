import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "src/environments/environment";

export class SentryLoader {
    public initLogging(callback?: Function, hasRoutes?: boolean) {
        let tracesSampleRate = 1.0;

        if (environment.production) {
            tracesSampleRate = 0.2;
        }

        const browserOptions: Sentry.BrowserOptions = {
            dsn: "https://2b9216b047f74723afe138ae64dad732@o1073115.ingest.sentry.io/4504056771117056",
            integrations: hasRoutes
                ? [
                      new Integrations.BrowserTracing({
                          routingInstrumentation: Sentry.routingInstrumentation
                      })
                  ]
                : [],

            environment: environment.name,
            beforeSend: (event: Sentry.Event, hint?: any) => {
                if (window.location.hostname === "localhost" && !environment.enableLocalSentryCapture) {
                    return null;
                }

                return event;
            },

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // tracesSampleRate: tracesSampleRate
            tracesSampleRate: tracesSampleRate,
            ignoreErrors: [
                // Random plugins/extensions
                "top.GLOBALS",
                "originalCreateNotification",
                "canvas.contentDocument",
                "MyApp_RemoveAllHighlights",
                "http://tt.epicplay.com",
                "Can't find variable: ZiteReader",
                "jigsaw is not defined",
                "ComboSearch is not defined",
                "http://loading.retry.widdit.com/",
                "atomicFindClose",
                // Facebook borked
                "fb_xd_fragment",
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                "bmi_SafeAddOnload",
                "EBCallBackMessageReceived",
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                "conduitPage",
                // Generic error code from errors outside the security sandbox
                // You can delete this if using raven.js > 1.0, which ignores these automatically.
                "Script error.",
                // Avast extension error
                "_avast_submit"
            ],
            denyUrls: [
                // Google Adsense
                /pagead\/js/i,
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
            ]
        };

        Sentry.init(browserOptions);

        if (callback) {
            callback();
        }
    }
}
