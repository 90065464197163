import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import * as planDetailConfig from "../configs/plan-detail.config";

@Injectable()
export class PlanTitleResolver implements Resolve<string> {
    constructor() {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
        const pageId = route.params["pageId"] || "";

        if (pageId) {
            const selectedPlan = planDetailConfig.planDetailPages[pageId];

            if (selectedPlan) {
                return selectedPlan.title;
            }
        }

        return "";
    }
}
