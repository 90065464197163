import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { map, Observable } from "rxjs";
import { QuestionnaireService } from "src/app/shared/services/questionnaire.service";

@Injectable({
    providedIn: "root"
})
export class RecommendedPlansGuard implements CanActivate {
    constructor(private questionnaireService: QuestionnaireService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.questionnaireService.getResultOverviewIds().pipe(
            map((resultIds: string[]) => {
                const showRoute = resultIds.length > 0;

                if (showRoute) {
                    return true;
                } else {
                    this.router.navigate(["/"]);
                    return false;
                }
            })
        );
    }
}
