import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlanOverview } from "src/app/common/interfaces/Plan.interface";

@Component({
    selector: "app-plan-overview",
    templateUrl: "./plan-overview.component.html",
    styleUrls: ["../../../../scss/plans.scss", "./plan-overview.component.scss"]
})
export class PlanOverviewComponent implements OnInit {
    @Output() linkClicked: EventEmitter<string> = new EventEmitter<string>();

    @Input() plan!: PlanOverview;

    constructor() {}

    ngOnInit(): void {}

    public handleClick(): void {
        this.linkClicked.emit(this.plan.title);
    }
}
