import { animate, group, query, state, style, transition, trigger } from "@angular/animations";

export const fadeIn = (duration: string) =>
    trigger("fadeIn", [
        state("void", style({ opacity: 0 })),
        state("*", style({ opacity: 1 })),
        transition("void => *", animate(duration)),
        transition("* => void", animate(duration))
    ]);

export const slideTo = (direction: string) => {
    const optional = { optional: true };
    return [
        query(
            ":enter, :leave",
            [
                style({
                    position: "absolute",
                    top: 0,
                    [direction]: 0,
                    width: "100%"
                })
            ],
            optional
        ),
        query(":enter", [style({ [direction]: "-100%" })]),
        group([
            query(":leave", [animate("600ms ease", style({ [direction]: "100%" }))], optional),
            query(":enter", [animate("600ms ease", style({ [direction]: "0%" }))])
        ])
    ];
};

export const slider = trigger("routeAnimations", [
    transition(":increment", slideTo("right")),
    transition(":decrement", slideTo("left"))
]);
